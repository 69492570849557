import { Routes, Route} from "react-router-dom";
import {Layout} from './layout/Layout';
import {LoginLayout} from './layout/LoginLayout';
import Loginpage from './pages/Login/login';
import ForgotPassword from './pages/ForgotPassword/index';
import Register from './pages/Register/index.js';
import Dashboard from './pages/Dashboard/index';
import DailySpacialDrink from './pages/DailySpecial/drink';
import DailySpacialFood from './pages/DailySpecial/food';
import Communication from './pages/Communication/index';
import BusinessVerify from './pages/Register/BusinessVerify';
import AlternateBusinessVerify from './pages/Register/AlternateBusinessVerify';
import Verification from './pages/Register/verification';
import Membership from './pages/Register/membership';
import CreateSpecial from './pages/CreateSpecial/index';
import CreateDailySpecial from './pages/CreateSpecial/dailySpecial';
import CreateGameSpecial from './pages/CreateSpecial/gameSpecial';
import CreatehappyHours from './pages/CreateSpecial/happyhours';
import CreateOnTap from './pages/CreateSpecial/onTap';
import ProfileSetting from './pages/profileSetting/index';
import DailyCreate from './pages/DailySpecial/create';
import DailyDrinkCreate from './pages/DailySpecial/drinkCreate';
import HappyHourDrink from './pages/HappyHour/drink';
import HappyHourFood from './pages/HappyHour/food';
import HappyCreate from './pages/HappyHour/create';
import GameCreate from './pages/GameDailySpecial/create';
import HappyDrinkCreate from './pages/HappyHour/drinkCreate';
import GameSpacialDrink from './pages/GameDailySpecial/drink';
import GameSpacialFood from './pages/GameDailySpecial/food';
import GameDrinkCreate from './pages/GameDailySpecial/drinkCreate';
import OnTapDrink from './pages/OnTap/drink';
import OnTapCreate from './pages/OnTap/create';
import RequestAdmin from './pages/RequestAdmin/index';
import UpgradeMembership from './pages/profileSetting/upgrade';
import Active from './pages/Accounts/active';
import Pending from './pages/Accounts/pending';
import Suspended from './pages/Accounts/suspended';
import PendingPayment from "./pages/Accounts/pendingPayment.js";
import AllAccounts from "./pages/Accounts/allAccounts.js"
import UserManagement from './pages/UserManagement/index';
import UserManagementView from './pages/UserManagement/view';
import UserManagementActive from './pages/UserManagement/active';
// import UserManagementSuspend from './pages/UserManagement/suspend';
import AdminMembership from './pages/Membership/index';
import AdminMembershipUpdate from './pages/Membership/update';
import RestaurantCommunicationGeneral from './pages/RestaurantCommunication/general';
import RestaurantCommunicationRequest from './pages/RestaurantCommunication/Request';
import MasterEntryFood from './pages/MasterEntry/food';
import MasterEntryDrink from './pages/MasterEntry/drinks';
import MasterEntryWinesTypes from './pages/MasterEntry/wine-type';
import MasterEntryBeer from './pages/MasterEntry/beer';
import MasterEntryCider from './pages/MasterEntry/cider';
import MasterEntryRegion from './pages/MasterEntry/region';
import MasterEntryContainer from './pages/MasterEntry/container';
import MasterEntrySize from './pages/MasterEntry/size';
import MasterEntrySports from './pages/MasterEntry/games';
import MasterEntryTeam from './pages/MasterEntry/team';
import ActiveView from './pages/Accounts/view';
import RestaurantPromo from "./pages/promotions/promocode";
import Advertisement from "./pages/Advertisement/index.js";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MasterEntryOther from "./pages/MasterEntry/other.js";
import MasterEntryTags from "./pages/MasterEntry/tags.js";




function RoutesComponent() {
    return (
        <>
          <Routes>
            <Route path='/' element={<LoginLayout />}>
                <Route index element={<Loginpage />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/register' element={<Register />} />
                <Route path='/verify-business'element={<ProtectedRoute><BusinessVerify /></ProtectedRoute>} />
                <Route path='/alternate-verify-business' element={<ProtectedRoute><AlternateBusinessVerify /></ProtectedRoute>} />
                <Route path='/Verification' element={<ProtectedRoute><Verification /></ProtectedRoute>} />
                <Route path='/membership' element={<ProtectedRoute><Membership /></ProtectedRoute>} />
               
            </Route>
            <Route path='' element={<ProtectedRoute><Layout /></ProtectedRoute>}>
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/accounts/active-restaurants' element={<Active />} />
                <Route path='/accounts/pending-restaurants' element={<Pending />} />
                <Route path='/accounts/suspended-restaurants' element={<Suspended />} />
                <Route path='/accounts/Payment-pending-restaurants' element={<PendingPayment />} />
                <Route path='/accounts/All-restaurants' element={<AllAccounts />} />
                <Route path='/daily-special/drink' element={<DailySpacialDrink />} />
                <Route path='/daily-special/food' element={<DailySpacialFood />} />
                <Route path='/daily-special/create' element={<DailyCreate />} />
                <Route path='/daily-special/drink/create' element={<DailyDrinkCreate />} />
                <Route path='/communication' element={<Communication />} />
                <Route path='/create-special' element={<CreateSpecial />} />
                <Route path='/create-special/daily-special' element={<CreateDailySpecial />} />
                <Route path='/create-special/game-special' element={<CreateGameSpecial />} />
                <Route path='/create-special/happy-hours' element={<CreatehappyHours />} />
                <Route path='/create-special/on-tap' element={<CreateOnTap />} />
                <Route path='/profile-setting' element={<ProfileSetting />} />
                <Route path='/profile-membership' element={<UpgradeMembership />} />
                <Route path='/happy-hour/drink' element={<HappyHourDrink />} />
                <Route path='/happy-hour/food' element={<HappyHourFood />} />
                <Route path='/happy-hour/create' element={<HappyCreate />} />
                <Route path='/happy-hour/drink/create' element={<HappyDrinkCreate />} />
                <Route path='/game-day-special/drink' element={<GameSpacialDrink />} />
                <Route path='/game-day-special/food' element={<GameSpacialFood />} />
                <Route path='/game-day-special/create' element={<GameCreate />} />
                <Route path='/game-day-special/drink/create' element={<GameDrinkCreate />} />
                <Route path='/on-tap/drink' element={<OnTapDrink />} />
                <Route path='/on-tap/create' element={<OnTapCreate />} />
                <Route path='/request-admin' element={<RequestAdmin />} />
                <Route path='/user-management' element={<UserManagement />} />
                <Route path='/user-management/view/:id' element={<UserManagementView />} />
                <Route path='/user-management/active' element={<UserManagementActive />} />
                {/* <Route path='/user-management/suspend' element={<UserManagementSuspend />} /> */}
                <Route path='/admin-membership' element={<AdminMembership />} />
                <Route path='/admin-membership/update' element={<AdminMembershipUpdate />} />
                <Route path='/restaurant-communication/general' element={<RestaurantCommunicationGeneral />} />
                <Route path='/restaurant-communication/request' element={<RestaurantCommunicationRequest/>} />
                <Route path='/master-entry/food' element={<MasterEntryFood />} />
                <Route path='/master-entry/drinks' element={<MasterEntryDrink />} />
                <Route path='/master-entry/wine-type' element={<MasterEntryWinesTypes />} />
                <Route path='/master-entry/beer' element={<MasterEntryBeer />} />
                <Route path='/master-entry/cider' element={<MasterEntryCider />} />
                <Route path='/master-entry/other' element={<MasterEntryOther />} />
                <Route path='/master-entry/region' element={<MasterEntryRegion />} />
                <Route path='/master-entry/container' element={<MasterEntryContainer />} />
                <Route path='/master-entry/size' element={<MasterEntrySize/>} />
                <Route path='/master-entry/tags' element={<MasterEntryTags/>} />
                <Route path='/master-entry/games' element={<MasterEntrySports/>} />
                <Route path='/master-entry/team' element={<MasterEntryTeam/>} />
                <Route path='/accounts/activeview-restaurants' element={<ActiveView />} />
                <Route path="/promotions" element={<RestaurantPromo/>}/>
                <Route path="/advertisement" element={<Advertisement/>}/>                
            
            </Route>
        </Routes >
        <ToastContainer/> 
        </>
      
    );
}

export default RoutesComponent;
